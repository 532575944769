import React, { useEffect, useState } from "react";
//import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useToken from "../../services/useToken";
import { Link, useLocation } from "react-router-dom";

export function OAuth2RedirectHandler() {
  const [authError, setAuthError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [returnUrl, setReturnUrl] = useState();
  const navigate = useNavigate();
  const { setToken } = useToken();

  const getQueryParams = (query = null) => {
    return (
      (query || window.location.search.replace("?", ""))

        // get array of KeyValue pairs
        .split("&")

        // Decode values
        .map((pair) => {
          let [key, val] = pair.split("=");

          return [key, decodeURIComponent(val || "")];
        })

        // array to object
        .reduce((result, [key, val]) => {
          result[key] = val;
          if (key.includes("error")) {
            localStorage.setItem("errorMsg",val)
            //setErrorMsg(val);
            return null;
          }
          return result;
        }, {})
    );
  };

  useEffect(() => {
    (async () => {
      const token = await getQueryParams();
      //console.log("token", token);
      //const token = this.getUrlParameter("access_token");
      //const refresh_token = this.getUrlParameter("refreshToken");
      //const query = new URLSearchParams(window.location.search);
      //console.log('token',token);
      //console.log("id", query.get("id"));

      if (token == null) {
        console.log('==========',errorMsg);
        setAuthError(true);
        navigate("/login");
      } else {
        //console.log('token',token);
        setToken(token);
        //localStorage.setItem("access_token",token);
        //localStorage.setItem("userId", id);
        returnUrl ? navigate(`/${returnUrl}`) : navigate("/");
      }
    })();
  });
  
}

//export default OAuth2RedirectHandler;
