const environment = {
  // api: "http://myhostel-env-1.eba-wuxzeqau.ap-south-1.elasticbeanstalk.com",
  // api: "http://myhosteltest-env.eba-8hgxnbsq.ap-south-1.elasticbeanstalk.com",
  // api: "https://staging-backend.myhostelroom.com",
  // api: "https://test-backend.myhostelroom.com",
  // api: "http://prod-backend.myhostelroom.com",
  // api: "http://local-testing-myhostelroom-env.ap-south-1.elasticbeanstalk.com",
  // api: "http://prod-clone-myhostelroom-env.ap-south-1.elasticbeanstalk.com",
  api: "https://secureprod.myhostelroom.com",
   // api: "http://localhost:5000",
  //  api: "https://test-backend.myhostelroom.com",
  // gMapKey: "AIzaSyDT5af1ZlVFgG8dkYVh3MyrrNFNMmld6J4",
  // api: "http://ec2-13-232-240-70.ap-south-1.compute.amazonaws.com:5000",
  gMapKey: "AIzaSyAvApha-tT7EEdKH8wvIOoi3_EprDvHa2A"

};

export default environment;
