import React, { useState, useEffect, useRef } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import BackButton from "../../components/back-button/BackButton";
import "./MakeEnquiry.scss";
import { TextField, Tooltip, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { verifyCaptcha, addMakeEnquiry } from "../../services/httpService";
import useToken from "../../services/useToken";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import MetaTag from "../../components/meta-tag/metatag";

export function MakeEnquiry() {
  const [loading, setLoading] = useState(true);
  const captchaRef = useRef(null);
  const [makeEnquiry, setMakeEnquiry] = useState({
    fullName: "",
    emailId: "",
    mobileNo: "",
    description: "",
  });
  const [valid_token, setValidToken] = useState([]);
  const [updateProfileError, setUpdateProfileError] = useState(false);
  const [updateRequiredFieldError, setUpdateRequiredFieldError] =
    useState(false);
  const [updateProfileSuccess, setUpdateProfileSuccess] = useState(false);
  // const SITE_KEY = "6LcA6aYmAAAAANP8q6FyrZyhCAzqJBEP2SpmdymM";
  //const SITE_KEY = "6LeWJ7wmAAAAACH2vaFCY_-FGfB_k2Itrc7MECoA";
  const SITE_KEY = "6LexVVEoAAAAAEs0rrEpTAK5GQuFog_4waomd7yu";
  // const token = captchaRef.current.getValue();

  function handleChange(e) {
    const { name, value } = e.target;
    setMakeEnquiry({
      ...makeEnquiry,
      [name]: value,
    });
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  const ValidateMobileNo = () => {
    var modileNo = document.getElementById("mobileNo").value;
    var lblError = document.getElementById("invalidMobileNo");
    lblError.innerHTML = "";
    var expr = /^[7-9]\d{9}$/;
    if (!expr.test(modileNo)) {
      lblError.innerHTML = "Please enter valid mobile number.";
    }
    if (modileNo == "") {
      lblError.innerHTML = "";
    }
  };
  const ValidateEmail = () => {
    var email = document.getElementById("emailId").value;
    var lblError = document.getElementById("invalidEmail");
    lblError.innerHTML = "";
    var expr = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!expr.test(email)) {
      lblError.innerHTML = "Please enter valid email address.";
    }
    if (email == "") {
      lblError.innerHTML = "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let captchaToken = captchaRef.current.getValue();
    captchaRef.current.reset();
    if (captchaToken) {
      let valid_token = await verifyToken(captchaToken);
      setValidToken(valid_token);

      if (
        valid_token[0].response.success === true &&
        makeEnquiry.fullName !== "" &&
        makeEnquiry.emailId !== "" &&
        makeEnquiry.mobileNo !== "" &&
        makeEnquiry.description !== ""
      ) {
        setLoading(true);
        let response = await addMakeEnquiry({
          body: {
            fullName: makeEnquiry.fullName,
            emailId: makeEnquiry.emailId,
            mobileNo: makeEnquiry.mobileNo,
            description: makeEnquiry.description,
          },
        });
        if (!response) {
          setLoading(false);
          setUpdateProfileError(true);
        } else {
          setLoading(false);
          setUpdateProfileSuccess(true);
          setMakeEnquiry({
            fullName: "",
            emailId: "",
            mobileNo: "",
            description: "",
          });
        }
      } else {
        setLoading(false);
        setUpdateRequiredFieldError(true);
        console.log("not verified");
      }
    }
  };
  const verifyToken = async (captchaToken) => {
    let APIResponse = [];
    try {
      let response = await verifyCaptcha({
        captchaToken,
      });
      console.log("verify", response);
      APIResponse.push(response);
      return APIResponse;
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setUpdateProfileError(false);
    setUpdateProfileSuccess(false);
    setUpdateRequiredFieldError(false);
  };

  return (
    <div className="MakeEnquiry">
      <MetaTag hostelName={"Default"} />
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {/* <div className="cu__top-toolbar">
            <BackButton />
          </div> */}
          <main className="cu__main-container">
            <Snackbar
              open={updateProfileError}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Server Error! Retry.
              </Alert>
            </Snackbar>
            <Snackbar
              open={updateRequiredFieldError}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Please fill all required fields!
              </Alert>
            </Snackbar>

            <Snackbar
              open={updateProfileSuccess}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Enquiry Successfully Sent!
              </Alert>
            </Snackbar>
            <section className="cu__left">
              <h2>Make Enquiry</h2>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="fullName"
                    name="fullName"
                    value={makeEnquiry.fullName}
                    style={{ width: "50%" }}
                    label="Full Name"
                    variant="filled"
                    onChange={handleChange}
                    required
                    // InputProps={{
                    //     readOnly: id > 0 ? true : false
                    // }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="emailId"
                    name="emailId"
                    value={makeEnquiry.emailId}
                    style={{ width: "50%" }}
                    label="Email"
                    variant="filled"
                    onKeyUp={ValidateEmail}
                    onChange={handleChange}
                    required
                    // InputProps={{
                    //     readOnly: id > 0 ? true : false
                    // }}
                  />
                  <Typography style={{ marginBottom: "0%" }}>
                    <span
                      style={{ paddingLeft: "0%", color: "red" }}
                      id="invalidEmail"
                    ></span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="mobileNo"
                    name="mobileNo"
                    value={makeEnquiry.mobileNo}
                    style={{ width: "50%" }}
                    label="Mobile No."
                    variant="filled"
                    onKeyUp={ValidateMobileNo}
                    inputProps={{ maxLength: 10 }}
                    onChange={handleChange}
                    required
                    // InputProps={{
                    //     readOnly: id > 0 ? true : false
                    // }}
                  />
                  <Typography style={{ marginBottom: "0%" }}>
                    <span
                      style={{ paddingLeft: "0%", color: "red" }}
                      id="invalidMobileNo"
                    ></span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    id="description"
                    name="description"
                    value={makeEnquiry.description}
                    style={{ width: "50%", height: "50px" }}
                    label="Query"
                    variant="outlined"
                    onChange={handleChange}
                    required
                    inputProps={{ maxLength: 255 }}
                    // multiline
                    // InputProps={{
                    //     readOnly: id > 0 ? true : false
                    // }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ReCAPTCHA sitekey={SITE_KEY} ref={captchaRef} />
                </Grid>
                <Stack
                  style={{ paddingBottom: "3%", paddingLeft: "39%" }}
                  spacing={2}
                >
                  <Button
                    onClick={handleSubmit}
                    component="label"
                    className="hd__book-now-btn"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </section>
            <aside className="cu__right"></aside>
          </main>
        </Container>
      )}

      <Footer />
    </div>
  );
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
