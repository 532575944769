import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import BackButton from "../../components/back-button/BackButton";
import "./AboutUs.scss";
import MetaTag from "../../components/meta-tag/metatag";

export function AboutUs() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="AboutUs">
      <MetaTag hostelName={"Default"} />
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {/* <div className="au__top-toolbar">
            <BackButton />
          </div> */}
          <main className="au__main-container">
            <section className="au__left">
              <h2 style={{ color: "#ff6600" }}>About Us</h2>
              <p>
                Welcome to Kyozon Hostels Pvt. Ltd., the premier provider of
                exceptional hostel accommodations in Pune. At Kyozon Hostels
                Pvt. Ltd., we are passionate about creating a welcoming and
                comfortable environment for students.
              </p>
              <h4>Our Vision</h4>
              <ul>
                <li>
                  To empower students and working professionals seeking
                  accommodations with home away from home, that offers a
                  seamless and enjoyable experience during their stay.
                </li>
              </ul>
              <h4>Our Mission </h4>
              <ul>
                <li>
                  {" "}
                  To be the biggest aggregator of hostel accommodations in India
                  by Year 2028
                </li>
                <li>
                  To impact and serve atleast 10,000 people across India by year
                  2025
                </li>
                <li>
                  To provide better returns to investors who would want to
                  monetise their properties.
                </li>
              </ul>
              <h4>Our Values </h4>
              <ul>
                <li>Integrity</li>
                <li>Quality</li>
                <li>Service with Warmth</li>
              </ul>
              <h4>Our Team </h4>
              <ul>
                <li>
                  {" "}
                  The bench strength at Kyozon Hostels Pvt. Ltd. consists of
                  experienced professionals who understand the unique needs and
                  preferences of our guests. We take pride in curating spaces
                  that foster a sense of community, allowing individuals to
                  connect, learn, and grow. With a strong emphasis on quality,
                  convenience, and safety, we aim to exceed expectations and
                  provide an unforgettable stay for every customer.
                </li>
              </ul>
              <h4>Our Strengths</h4>
              <ul>
                <li>
                  <h4>Unmatched Comfort: </h4>
                  <ul>
                    <li>
                      We prioritize creating a homely atmosphere by providing
                      cozy and well-furnished accommodations that cater to the
                      needs of our guests.
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Prime Locations: </h4>
                  <ul>
                    <li>
                      Our hostels are strategically located in desirable areas,
                      offering easy access to educational institutions,
                      transportation, and popular landmarks.
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Modern Amenities: </h4>

                  <ul>
                    <li>
                      We offer a range of modern amenities, including high-speed
                      internet, common areas, study spaces, and 24/7 security,
                      to enhance the overall living experience.
                    </li>
                  </ul>
                </li>
              </ul>
              <h4>Why Choose Us</h4>
              <ul>
                <li>
                  <h4>Commitment to Quality: </h4>

                  <ul>
                    <li>
                      We are dedicated to maintaining the highest standards of
                      cleanliness, hygiene, and overall quality throughout our
                      hostels.
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Personalized Service: </h4>
                  <ul>
                    <li>
                      Our attentive and friendly staff is available to assist
                      students, ensuring a personalized and responsive approach
                      to their needs.
                    </li>
                  </ul>
                </li>
                <li>
                  <h5>Vibrant Community: </h5>
                  <ul>
                    <li>
                      We foster a vibrant community spirit, organizing events
                      and activities that encourage interaction, cultural
                      exchange, and personal growth among our students.
                    </li>
                    <li>
                      We take pride in our legacy of being in the realty
                      business with a successful track record of having
                      constructed and delivered exceptional homes across Pune.
                    </li>
                    <li>
                      We believe in creating a positive impact and leaving a
                      lasting legacy in the communities we serve.
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                Explore our website to discover more about our range of hostels,
                amenities, and services. If you are a student seeking a
                comfortable and convenient place to stay, Kyozon Hostels Pvt.
                Ltd. is here to make your experience unforgettable.
              </p>
              <p>
                We invite you to book your stay with us and experience the
                warmth, comfort, and camaraderie that sets Kyozon Hostels Pvt.
                Ltd. apart.
              </p>
            </section>
            <aside className="au__right"></aside>
          </main>
        </Container>
      )}

      <Footer />
    </div>
  );
}
